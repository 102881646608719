<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>대상물조치대상관리</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<!-- <div class="panel-heading">
				<h6 class="panel-title">
					<i class="icon-search4 position-left"></i>
					<b>검색조건</b>
				</h6>
				<div class="heading-elements">
					<ul class="icons-list">
						<li><a @click="pannelHidden" data-action="collapse"></a></li>
					</ul>
				</div>
			</div> -->
			<div class="panel-body" style="width: 1330px; margin: 20px 0px 0px auto; padding-left: 0px">
				<form action="#" @submit.prevent="search">
					<div class="row">
						<div class="col-md-2" style="width: 320px; margin-left: -20px">
							<div class="form-group">
								<label>{{ detailsFieldMap.objectId }}</label>
								<div class="select-wrapper">
									<!-- <select2 v-model="searchVM.objectId" :options="searchOptions.objectOptions"> -->
									<select2 v-model="searchVM.objectId">
										<option value="">전체</option>
										<option v-for="(row, index) in searchOptions.objectOptions" :key="index" :value="row.value">{{ row.text }}</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="col-md-2" style="width: 320px; margin-left: -20px">
							<div class="form-group">
								<label>{{ detailsFieldMap.targetId }}</label>
								<!-- <select2 v-model="searchVM.targetId" :options="searchOptions.targetOptions"> -->
								<div class="select-wrapper">
									<select2 v-model="searchVM.targetId">
										<option value="">전체</option>
										<option v-for="(row, index) in searchOptions.targetOptions" :key="index" :value="row.value">{{ row.text }}</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="col-md-2" style="width: 280px; margin-left: -20px">
							<div class="form-group">
								<label style="width: 120px">{{ detailsFieldMap.aiAplyDvsnCd }}</label>
								<!-- <select2 v-model="searchVM.aiAplyDvsnCd" :options="searchOptions.aiAplyDvsnCdOptions"> -->
								<div class="select-wrapper">
									<select2 v-model="searchVM.aiAplyDvsnCd">
										<option value="">전체</option>
										<option v-for="(row, index) in searchOptions.aiAplyDvsnCdOptions" :key="index" :value="row.cd">{{ row.cdNm }}</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="col-md-2" style="width: 280px">
							<div class="form-group">
								<label style="width: 120px">{{ detailsFieldMap.useYn }}</label>
								<div class="select-wrapper">
									<select2 v-model="searchVM.useYn" :options="searchOptions.useYn">
										<option value="">전체</option>
									</select2>
								</div>
							</div>
						</div>
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
				<button type="button" class="btn btn-labeled bg-primary" @click="startCreate">
					<b><i class="icon-plus3"></i></b>
					추가
				</button>
			</div>

			<KendoGrid
				ref="grid"
				:auto-bind="true"
				:api-url="apiUrl.pageListApi"
				:columns="gridColumns"
				:apply-search-condition="applySearchStateOnGridLoad"
				@selected-row-item-changed="selectedRowItemChanged"
			></KendoGrid>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isEditMode || isCreateMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body panel-body-center">
					<div class="row panel-detail">
						<fieldset>
							<legend class="text-semibold col-md-12">
								<i class="icon-cog3 position-left"></i>
								대상물-조치대상관리 정보
							</legend>
							<div class="col-md-6 inputDiv1">
								<!-- 대상물 코드 -->
								<jarvis-field :label="detailsFieldMap.objectId" :required="true" field="detailsItem.objectId">
									<select2
										v-model="detailsItem.objectId"
										:options="detailsOptions.objectOptions"
										:disabled="!isCreateMode"
										data-vv-name="detailsItem.objectId"
										v-validate="validationRule.detailsItem.objectId"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 조치대상 코드 -->
								<jarvis-field :label="detailsFieldMap.targetId" :required="true" field="detailsItem.targetId">
									<select2
										v-model="detailsItem.targetId"
										:options="detailsOptions.targetOptions"
										:disabled="!isCreateMode"
										data-vv-name="detailsItem.targetId"
										v-validate="validationRule.detailsItem.targetId"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 우선순위 -->
								<jarvis-field
									:label="detailsFieldMap.priority"
									required="true"
									field="detailsItem.priority"
									v-model="detailsItem.priority"
									data-vv-name="detailsItem.priority"
									v-validate="validationRule.detailsItem.priority"
									@keyup="checkItHasOnlyNumber($event)"
								></jarvis-field>
								<!-- AI 인식 -->
								<jarvis-field :label="detailsFieldMap.aiAplyDvsnCd" field="detailsItem.aiAplyDvsnCd" required="true">
									<select2
										v-model="detailsItem.aiAplyDvsnCd"
										data-vv-name="detailsItem.aiAplyDvsnCd"
										v-validate="validationRule.detailsItem.aiAplyDvsnCd"
									>
										<option value="">선택</option>
										<option v-for="(row, index) in detailsOptions.aiAplyDvsnCdOptions" :key="index" :value="row.cd">{{ row.cdNm }}</option>
									</select2>
								</jarvis-field>
								<!-- 맵핑사용여부 -->
								<jarvis-field :label="detailsFieldMap.useYn" field="detailsItem.useYn" required="true">
									<select2
										v-model="detailsItem.useYn"
										:options="detailsOptions.useYn"
										data-vv-name="detailsItem.useYn"
										v-validate="validationRule.detailsItem.useYn"
									>
										<option value="">선택</option>
									</select2>
								</jarvis-field>
								<!-- 안전 조치 요약 -->
								<div class="form-group">
									<label class="col-lg-3 control-label" style="margin-right: 0px">{{ detailsFieldMap.safeActnSummery }}:</label>
									<div class="col-lg-9">
										<textarea
											class="form-control"
											id="firstInputCreate"
											style="resize: none; height: 100px"
											v-model="detailsItem.safeActnSummery"
											placeholder="안전 조치 요약 내용"
											:maxlength="maxLength.safeActnSummery"
										></textarea>
									</div>
								</div>
								<!-- 위험 판단 메시지 -->
								<jarvis-field
									:label="detailsFieldMap.dangerMesg"
									v-model="detailsItem.dangerMesg"
									v-validate="validationRule.detailsItem.dangerMesg"
									:maxLength="maxLength.dangerMesg"
								></jarvis-field>
								<!-- 메모 -->
								<div class="form-group">
									<label class="col-lg-3 control-label" style="margin-right: 0px">{{ detailsFieldMap.memo }}:</label>
									<div class="col-lg-9">
										<textarea
											class="form-control"
											style="resize: none; height: 80px"
											v-model="detailsItem.memo"
											placeholder="메모"
											:maxlength="maxLength.memo"
										></textarea>
									</div>
								</div>
							</div>

							<div class="col-md-6">
								<!-- 등록일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regDtm"
									:value="detailsItem.regDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 등록자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.regUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.regUser"
									:value="detailsItem.regUser"
								></jarvis-field>
								<!-- 수정일, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updDtm"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updDtm"
									:value="detailsItem.updDtm | toDisplayDateTime"
								></jarvis-field>
								<!-- 수정자, 수정모드만 표시 -->
								<jarvis-field
									:label="detailsFieldMap.updUser"
									disabled="true"
									v-if="isEditMode"
									field="detailsItem.updUser"
									:value="detailsItem.updUser"
								></jarvis-field>
							</div>
						</fieldset>
					</div>
					<div class="text-right" v-if="!isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="createData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>
					<div class="text-right" v-if="isEditMode">
						<button type="button" class="btn btn-labeled mx-sm-1" @click="closeDetails">
							<b><i class="icon-cross"></i></b>
							닫기
						</button>
						<button type="button" class="btn btn-labeled bg-warning mx-sm-1" @click="deleteData">
							<b><i class="icon-minus3"></i></b>
							삭제
						</button>
						<button type="button" class="btn btn-labeled bg-primary" @click="updateData">
							<b><i class="icon-checkmark3"></i></b>
							저장
						</button>
					</div>

					<span v-if="debug">
						isCreateMode: {{ isCreateMode }}
						<br />
						isEditMode: {{ isEditMode }}
						<br />
						detailsItemOriginal: {{ detailsItemOriginal }}
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention; //eslint-disable-line no-unused-vars
const objectTargetApi = apiIndex.objectTarget;

//상세필드검증규칙
const detailsValidationRule = {
	common: {
		//공통
		objectId: 'required',
		targetId: 'required',
		priority: 'required',
		aiAplyDvsnCd: 'required',
		useYn: 'required',
		memo: 'max:4000',
		dangerMesg: 'max:100',
	},
	edit: {
		//수정
		priority: 'required',
	},
	create: {
		//생성
		priority: 'required',
	},
};

const maxLength = {
	safeActnSummery: '4000',
	memo: '4000',
	dangerMesg: '100',
};

_.extend(detailsValidationRule.edit, detailsValidationRule.common);
_.extend(detailsValidationRule.create, detailsValidationRule.common);

export default {
	components: {
		select2: Select2,
		'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('objectTargetPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;

		// 기존 라이브러리를 사용하기 위해 변환
		this.pageParam.aiAplyDvsnCdOptions.forEach(function (e) {
			e.text = e.cdNm;
			e.value = e.cd;
		});

		const commonCodeList = JSON.parse(sessionStorage.getItem('commonCodeList'));

		this.detailsOptions = {
			objectOptions: this.pageParam.objectOptions,
			targetOptions: this.pageParam.targetOptions,
			aiAplyDvsnCdOptions: this.pageParam.aiAplyDvsnCdOptions,
			useYn: commonCodeList.filter(item => item.grpCd == 'yn'),
		};
		this.searchOptions = {
			objectOptions: this.detailsOptions.objectOptions,
			targetOptions: this.detailsOptions.targetOptions,
			aiAplyDvsnCdOptions: this.detailsOptions.aiAplyDvsnCdOptions,
			useYn: this.detailsOptions.useYn,
		};

		this.gridColumns.filter(item => item.field == 'aiAplyDvsnCd')[0].mapData = this.detailsOptions.aiAplyDvsnCdOptions;
		this.gridColumns.filter(item => item.field == 'useYn')[0].mapData = this.detailsOptions.useYn;
		axiosExtention = this.$jarvisExtention.axiosExtention;
		this.$validator.localize('ko', { attributes: this.$jarvisExtention.addKeyPrefix(this.detailsFieldMap, 'detailsItem.') });
		this.search();
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			excelApi: objectTargetApi.exlObjectTarget,
			pageListApi: objectTargetApi.inqObjectTarget,
			detailsApi: objectTargetApi.objectTarget,
		},
		//검색VM
		searchVM: {
			objectId: '',
			targetId: '',
			aiAplyDvsnCd: '01', // AI판별
			useYn: '1', //사용
		},
		//적용된 검색조건
		searchState: {},
		//상세 select options
		detailsOptions: {},
		//검색VM-Select옵션
		searchOptions: {},
		//그리드설정

		gridColumns: [
			{ field: 'objectId', title: '대상물 코드', width: '10%', align: 'center' },
			{ field: 'objectNm', title: '대상물 명', width: '16%' },
			{ field: 'targetId', title: '조치대상 코드', width: '10%', align: 'center' },
			{ field: 'targetNm', title: '조치대상 명', width: '16%' },
			{ field: 'priority', title: '우선순위', width: '8%', align: 'center' },
			{
				field: 'aiAplyDvsnCd',
				title: 'AI 적용 구분',
				dataFormat: 'optionMap',
				width: '10%',
				align: 'center',
			},
			{
				field: 'useYn',
				title: '사용여부',
				dataFormat: 'optionMap',
				width: '10%',
				align: 'center',
			},
			{ field: 'regDtm', title: '등록일', dataFormat: 'YYYY-MM-DD', width: '10%', align: 'center' },
			{ field: 'updDtm', title: '수정일', dataFormat: 'YYYY-MM-DD', width: '10%', align: 'center' },
		],
		//상세필드명맵
		detailsFieldMap: {
			objectId: '대상물코드',
			objectNm: '대상물명 ',
			targetId: '조치대상코드',
			targetNm: '조치대상명',
			priority: '우선순위',
			aiAplyDvsnCd: 'AI 적용 구분',
			useYn: '사용여부',
			memo: '메모',
			regDtm: '등록일',
			regUser: '등록자',
			updDtm: '최종 수정일',
			updUser: '최종 수정자',
			safeActnSummery: '안전조치 요약',
			dangerMesg: '위험판단 메시지',
		},
		//인풋 최댓값 설정
		maxLength,
		//상세편집 원본
		detailsItemOriginal: {},
		//상세편집 수정본
		detailsItem: {},
		debug: false,
	}),
	computed: {
		isEditMode() {
			return !_.isEmpty(this.detailsItem) && !_.isEmpty(this.detailsItemOriginal);
		},
		isCreateMode() {
			return !_.isEmpty(this.detailsItem) && _.isEmpty(this.detailsItemOriginal);
		},
		validationRule() {
			var rule = null;
			if (this.isEditMode) {
				if (this.detailsItem.isChangePassword) {
					rule = $.extend(true /*deep*/, { password: 'required|min:4|max:20' }, detailsValidationRule.edit);
				} else {
					rule = detailsValidationRule.edit;
				}
			} else {
				rule = detailsValidationRule.create;
			}
			return { detailsItem: rule };
		},
	},
	watch: {
		detailsItem(newVal) {
			this.$validator.reset(); //Validation상태 초기화
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
		isCreateMode() {
			let element = document.getElementsByClassName('inputDiv1')[0];
			if (this.isCreateMode) {
				element.classList.add('col-lg-offset-3');
			} else {
				element.classList.remove('col-lg-offset-3');
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		focusFirstInput(inputId) {
			document.getElementById(inputId).focus();
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		//검색적용
		search() {
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			this.$nextTick(function () {
				this.loadGrid();
			});
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName =
						'대상물조치대상관리_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			if (!_.isEmpty(selectedRowItem)) {
				//수정시작
				this.startEdit(selectedRowItem.objectId, selectedRowItem.targetId);
				setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
		//데이터 추가 시작
		startCreate() {
			this.detailsItemOriginal = {};
			this.detailsItem = {
				objectId: '',
				targetId: '',
				aiAplyDvsnCd: '',
				useYn: '',
				safeActnSummery: null,
				dangerMesg: null,
				memo: null,
			};
			setTimeout(() => this.focusFirstInput('firstInputCreate'), 500);
		},
		//데이터 수정 시작
		startEdit(objectId, targetId) {
			const closure = this;
			this.$axios
				.get(closure.apiUrl.pageListApi + '/' + objectId + '/' + targetId)
				.then(function (response) {
					response;
					closure.detailsItemOriginal = $.extend(true /*deep*/, {}, response.data);
					closure.detailsItem = $.extend(true /*deep*/, {}, response.data);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//신규저장
		createData() {
			const closure = this;
			this.$validator.validateAll().then(function (success) {
				if (success == false) {
					console.log('validation.errors: ', closure.errors);
					return;
				}
				closure.$axios
					.post(closure.apiUrl.detailsApi, closure.detailsItem)
					.then(function (response) {
						response;
						closure.loadGrid();
					})
					.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
			});
		},
		//수정저장
		updateData() {
			const closure = this;
			this.$validator.validateAll().then(function (success) {
				if (success == false) {
					console.log('validation.errors: ', closure.errors);
					return;
				}
				if (confirm('수정한 데이터를 저장하시겠습니까?')) {
					const objectId = closure.detailsItem.objectId;
					const targetId = closure.detailsItem.targetId;
					closure.$axios
						.put(closure.apiUrl.detailsApi + '/' + objectId + '/' + targetId, closure.detailsItem)
						.then(function (response) {
							response;
							closure.loadGrid();
						})
						.catch(axiosExtention.buildErrorHandler(closure.detailsFieldMap));
				}
			});
		},
		//삭제
		deleteData() {
			if (!confirm('해당 데이터를 영구적으로 삭제하시겠습니까?')) {
				return;
			}
			const closure = this;
			const objectId = closure.detailsItem.objectId;
			const targetId = closure.detailsItem.targetId;
			this.$axios
				.delete(closure.apiUrl.detailsApi + '/' + objectId + '/' + targetId)
				.then(function (response) {
					response;
					closure.loadGrid();
				})
				.catch(axiosExtention.buildErrorHandler());
			// });
		},
		checkItHasOnlyNumber(event) {
			this.detailsItem.priority = event.target.value.replaceAll(/[^0-9]/g, '');
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}
#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}

.select-wrapper {
	min-width: 170px !important;
	width: 170px !important;
}

.select-wrapper > span {
	width: 170px !important;
}
#rootVM > .panel .panel-body .form-group label {
	/* width: 60%; */
	width: 90px;
	margin-top: 5px;
}
#rootVM > .panel .panel-body .form-group input {
	max-width: 200px;
	margin-left: 15px;
}
label {
	margin-right: 10px;
}
</style>
